<template>
  <v-list>
    <v-subheader>
      <div class="flex align-center justify-space-between">
        <div class="d-flex align-center">
          <span class="text-h5 font-weight-bold">
            {{ $t("notifications_title") }}
          </span>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                class="ml-2"
                color="primary"
                @click="refreshNotifications"
                v-on="on"
                v-bind="attrs"
              >
                mdi-refresh
              </v-icon>
            </template>
            <span>{{ $t("refresh_label") }}</span>
          </v-tooltip>
        </div>
        <v-tooltip bottom v-if="!isLoading && getFilteredNotifications.length">
          <template #activator="{ on, attrs }">
            <v-icon
              class="ml-4"
              color="error"
              @click="clearNotifications"
              v-on="on"
              v-bind="attrs"
            >
              mdi-trash-can-outline
            </v-icon>
          </template>
          <span>{{ $t("delete_notifications_label") }}</span>
        </v-tooltip>
      </div>
    </v-subheader>
    <v-divider class="mb-4" />
    <div class="pa-2">
      <v-text-field
        outlined
        :label="$t('search_label')"
        hide-details
        dense
        v-model="searchTerm"
      />
    </div>
    <v-list-item
      v-if="isLoading"
      class="d-flex flex-column align-center justify-center"
    >
      <p class="text-subtitle-1">{{ $t("fetching_notifications_message") }}</p>
      <v-progress-circular size="28" color="primary" indeterminate />
    </v-list-item>
    <v-list-item v-if="!isLoading && !getFilteredNotifications.length">
      <v-list-item-content>
        <v-list-item-title>
          <p class="text-subtitle-1 text-center">
            {{ $t("no_notifications_message") }}
          </p>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <div v-if="!isLoading">
      <v-list-item
        v-for="(notification, index) in getFilteredNotifications"
        :key="index"
        class="flex-grow-1"
      >
        <notification
          :notification-index="index"
          @notification-clicked="handleNotificationClick"
        />
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Notification from "@/components/Notification.vue";
export default {
  name: "Notifications",
  components: { Notification },
  data: () => ({
    isInitialized: false,
    searchTerm: "",
  }),
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
      currentUser: (state) => state.users.currentUser,
      isLoading: (state) => state.notifications.isLoading,
      notifications: (state) => state.notifications.notifications,
    }),
    ...mapGetters({
      availableFlows: "company/getAvailableBotFlows",
    }),
    getFilteredNotifications() {
      return this.notifications.filter((not) =>
        not.searchTerm.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
  },
  mounted() {
    this.refreshNotifications();
  },
  methods: {
    ...mapActions({
      showSnackbar: "common/showSnackbar",
      fetchNotifications: "notifications/fetchNotifications",
      clearNotifications: "notifications/clearNotifications",
    }),
    refreshNotifications() {
      if (this.isLoading) return;
      this.fetchNotifications();
    },
    handleNotificationClick(notification) {
      const flowToRedirectTo = this.availableFlows.find(
        (flow) => flow.name === notification.title
      );
      this.$store.commit("company/setCurrentFlow", flowToRedirectTo);
      const query = {
        from: "notification",
        id: notification.id,
      };
      if (notification.type === "webhook") {
        query.webhook = true;
      }
      if (notification.type === "approval") {
        query.approval = true;
        this.$router.push({
          path: `/approval`,
          query,
        });
        return;
      }
      this.$router.push({
        path: `/flow`,
        query,
      });
    },
  },
};
</script>
