<template>
  <v-sheet
    v-if="currentNotification"
    rounded
    class="pa-2 mb-2 notification flex-grow-1"
    :class="{
      cursor__pointer:
        currentNotification.status === 'PENDING' ||
        currentNotification.status === 'IN PROGRESS',
    }"
    outlined
    width="100%"
    @click.native="emitNotificationClick"
  >
    <div class="d-flex mb-4">
      <v-icon class="mr-2" :color="getNotificationColor">
        {{ getNotificationIcon }}
      </v-icon>
      <p :color="getNotificationColor" class="text-subtitle-2 mb-0">
        {{ currentNotification.title }}
      </p>
      <v-spacer />
      <v-tooltip left v-if="!isLoading && notifications.length">
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2"
            color="error"
            @click="deleteNotification"
            v-on="on"
            v-bind="attrs"
          >
            mdi-trash-can-outline
          </v-icon>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </div>
    <v-expansion-panels v-model="payloadDataPanel">
      <v-expansion-panel @click.capture="managePanel">
        <v-expansion-panel-header> Notification data </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div @click.capture="managePanel">
            <div class="d-flex align-center mb-2">
              <v-chip
                :class="getNotificationStatus"
                class="text-uppercase white--text mr-2"
                small
                readonly
              >
                {{ currentNotification.status }}
              </v-chip>
              <v-chip
                :class="getNotificationColor"
                class="text-uppercase white--text"
                small
                readonly
              >
                {{ currentNotification.type }}
              </v-chip>
            </div>
            <p class="mb-4 text--secondary caption">
              Created at: {{ currentNotification.createdAt }}
            </p>
            <div class="d-flex flex-column">
              <p class="text-body-1 mb-2">Notification payload</p>
              <div v-for="(object, key) in filteredNotificationData" :key="key">
                <span class="font-weight-bold text-body-2 text-capitalize">
                  {{ key.replaceAll("_", " ") }}:</span
                >
                <span> {{ object }}</span>
              </div>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Notification",
  props: {
    notificationIndex: {
      type: Number,
      default: 0,
    },
  },
  created() {
    this.currentNotification = this.notifications[this.notificationIndex];
  },
  data: () => ({
    currentNotification: null,
    payloadDataPanel: 1,
  }),
  computed: {
    ...mapState({
      notifications: (state) => state.notifications.notifications,
      isLoading: (state) => state.notifications.isLoading,
    }),
    filteredNotificationData() {
      const filteredData = {};
      const filteredKeys = [
        "rejection_mapping",
        "approval_auth_on_execution",
        "approval_mapping",
        "rejection_auth_on_execution",
        "details_config",
        "header_config",
        "approval_route_details",
        "current_level",
        "approval_hierarchy",
        "available_levels",
        "line_items",
        "obot_approval_type",
      ];
      Object.keys(this.currentNotification.data).forEach((key) => {
        if (!filteredKeys.includes(key)) {
          filteredData[key] = this.currentNotification.data[key];
        }
      });
      return filteredData;
    },
    getNotificationIcon() {
      switch (this.currentNotification.type) {
        case "informational":
          return "mdi-information-box";
        case "approval":
          return "mdi-check-decagram";
        case "webhook":
          return "mdi-webhook";
        case "link":
          return "mdi-link-box";
        default:
          return "mdi-message";
      }
    },
    getNotificationColor() {
      switch (this.currentNotification.type) {
        case "approval":
          return "green darken-1";
        case "webhook":
          return "blue-grey darken-1";
        case "link":
          return "orange darken-1";
        default:
          return "primary";
      }
    },
    getNotificationStatus() {
      switch (this.currentNotification.status) {
        case "PENDING":
          return "orange darken-1";
        case "APPROVED":
          return "green darken-1";
        case "REJECTED":
        case "IN PROGRESS":
          return "red darken-1";
        case "link":
          return "mdi-link-box";
        default:
          return "primary";
      }
    },
  },
  methods: {
    emitNotificationClick() {
      if (
        this.currentNotification.status !== "PENDING" &&
        this.currentNotification.status !== "IN PROGRESS"
      )
        return;
      this.$emit("notification-clicked", this.currentNotification);
    },
    deleteNotification() {
      this.$store.dispatch(
        "notifications/deleteNotification",
        this.currentNotification.id
      );
    },
    managePanel(event) {
      event.stopPropagation();
      event.preventDefault();
    },
  },
};
</script>
